<template>
  <div class="container add-global-modal-main-container import-modal">
    <h1 class="add-global-modal-main-title">Importar {{ nameImport }}</h1>
    <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
    <span class="global-form-hint-color"> Recuerda: </span> Importar información
    al sistema puede tardar varios minutos en lo que es subida, puedes utilizar
    otras secciones del sistema en lo que este proceso termina, estos nuevos
    elementos solo seran visibles al refresar el sitio, por lo que debe
    refrescar el sitio una vez pasado algunos minutos.
    <strong
      >Asegurate que el contenido del archivo es el deseado antes de continuar
      con la importación.</strong
    >
    <b-field>
      <b-upload
        v-model="file"
        :multiple="false"
        drag-drop
        expanded
        required
        accept=".csv"
        class="import-input"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"></b-icon>
            </p>
            <p>Arraste su archivo o haga click y seleccione su archivo</p>
          </div>
        </section>
      </b-upload>
    </b-field>
    <div class="tags absolut-center-row">
      <span class="tag is-primary" v-if="file">
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteInformation()"
        ></button>
      </span>
    </div>
    <div class="add-import-controls">
      <b-button
        type="is-success"
        @click="downloadFormatFile()"
        class="mobile-button"
        >Descargar archivo de muestra</b-button
      >
      <b-button
        type="is-success"
        @click="uploadFile()"
        :disabled="!file"
        class="secondary-button"
        >Importar</b-button
      >
    </div>
  </div>
</template>

<script>
// Cleave variable initialization to bind formats to inputs
import moment from "moment";
import { EventBus } from "../../../event-bus";
import Cleave from "cleave.js";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "ImportFileModal",
  components: {},
  directives: { cleave },
  props: [
    "importType",
    "nameImport",
    "afterAction",
    "extraParams",
    "localConversion",
    "localConversionName",
  ],
  data() {
    return {
      file: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc uploads new information to the database
     */
    async uploadFile() {
      try {
        if (this.file) {
          const importData = {
            file: this.file,
            dataType: this.importType,
            extraParams: this.extraParams,
          };

          if (this.localConversion) {
            let response = await this.$store.dispatch(
              "IMPORTLOCALCSV",
              importData
            );
            EventBus.$emit(this.localConversionName, { ...response });
            this.closeModal();
            return;
          }

          this.$store.commit("SET_LOADING", true);
          let response = await this.$store.dispatch("IMPORTCSV", importData);
          if (response === "Success") {
            this.deleteInformation();
            if (this.afterAction) {
              await this.$store.dispatch(this.afterAction, {
                startDate: new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate() - 15
                ),
                endDate: new Date(),
              });
            }
            this.$store.commit("SET_LOADING", false);
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message:
                "Hemos recibido con éxito la información!! Seguimos trabajando para importarla, se te notificará una vez terminado el proceso. Este proceso por lo regular tarda un par de minutos, mientras tanto puedes seguir disfrutando de tu experiencia! 😃",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al importar la información",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            this.$store.commit("SET_LOADING", false);
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al importar la información",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.$store.commit("SET_LOADING", false);
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al importar la información",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        this.$store.commit("SET_LOADING", false);
      }
    },
    async downloadFormatFile() {
      try {
        let fileInformation;

        // Depending on the import file get the format object
        if (this.importType === "articles") {
          fileInformation = this.getExportedFileInformation("articles");
        } else if (this.importType === "warehouseArtciles") {
          fileInformation =
            this.getExportedFileInformation("warehouseArtciles");
        } else if (this.importType === "providers") {
          fileInformation = this.getExportedFileInformation("providers");
        } else if (this.importType === "providersArticlesPrices") {
          fileInformation = this.getExportedFileInformation(
            "providersArticlesPrices"
          );
        } else if (this.importType === "priceLists") {
          fileInformation = this.getExportedFileInformation("priceLists");
        } else if (this.importType === "bankMovements") {
          fileInformation = this.getExportedFileInformation("bankMovements");
        } else if (this.importType === "clients") {
          fileInformation = this.getExportedFileInformation("clients");
        } else if (this.importType === "priceListsArticles") {
          fileInformation =
            this.getExportedFileInformation("priceListsArticles");
        } else if (this.importType === "expenses") {
          fileInformation = this.getExportedFileInformation("expenses");
        } else if (this.importType === "presentations") {
          fileInformation = this.getExportedFileInformation("presentations");
        } else if (this.importType === "requestOrder") {
          fileInformation = this.getExportedFileInformation("requestOrder");
        } else if (this.importType === "articlesPrediction") {
          fileInformation =
            this.getExportedFileInformation("articlesPrediction");
        }

        // Call Export action from store to generate export file
        const response = await this.$store.dispatch("EXPORTCSV", {
          data: fileInformation,
          name: `${this.nameImport}  ${moment().format("l").toString()}`,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se creó exitosamente el archivo de muestra",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al crear el archivo de muestra",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (e) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al crear el archivo de muestra",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @des generated object to show correct format f import file
     * @param exportFileType the type of import that will be done
     * @returns {array} array with one item showing the correct format of the object of the import
     */
    getExportedFileInformation(exportFileType) {
      if (exportFileType === "articles") {
        return [
          {
            "CLAVE DEL ARTICULO (Requerido)": "123ABC",
            "DESCRIPCION (Requerido)": "Pala de madera",
            "ACTIVO PARA VENTA (Requerido)": "S",
            "ACTIVO PARA COMPRAS (Requerido)": "S",
            "ACTIVO PARA COMPRAS INTELIGENTES (Requerido)": "N",
            "CLAVE PARA CLASIFICACION INTERNA (Opcional)": "Palas",
            "CODIGO SAT (Requerido)": "01010101",
            "UNIDAD DE MEDIDA BASE (Requerido)": "PIEZA",
            "URL (Opcional)": "https://www.coconutcontrol.com/",
            "OBSERVACIONES (Opcional)": "Observaciones de muestra",
            "FECHA ULTIMA VENTA (Opcional)": "01/01/2021",
            "FECHA ULTIMA COMPRA (Opcional)": "01/01/2021",
            "PESO (KGS) (Opcional)": 5,
            "VOLUMEN (MT3) (Opcional)": 10,
            "COSTO MINIMO (Requerido)": 1000,
            "PORCENTAJE DE IVA (Requerido)": 16,
            "PORCENTAJE DE IEPS (Requerido)": 0,
            "CODIGO DE BARRAS (Opcional)": 7501206644812,
            "CLAVE DE LA MARCA (Opcional)": "GENERAL",
            "CLAVE DE LA FAMILIA (Opcional)": "HERRAMIENTAS",
            "CLAVE DE LA  SUBFAMILIA (Opcional)": "JARDINERIA",
            "CLAVE DE LA  SUBSUBFAMILIA (Opcional)": "MADERA",
            "CLAVE DE LA SUBSUBSUBFAMILIA (Opcional)": "PALAS",
          },
          {
            "CLAVE DEL ARTICULO (Requerido)": "123ABCD",
            "DESCRIPCION (Requerido)": "Pala de metal",
            "ACTIVO PARA VENTA (Requerido)": "N",
            "ACTIVO PARA COMPRAS (Requerido)": "S",
            "ACTIVO PARA COMPRAS INTELIGENTES (Requerido)": "S",
            "CLAVE PARA CLASIFICACION INTERNA (Opcional)": "Palas",
            "CODIGO SAT (Requerido)": "01010101",
            "UNIDAD DE MEDIDA BASE (Requerido)": "PIEZA",
            "URL (Opcional)": "https://www.coconutcontrol.com/",
            "OBSERVACIONES (Opcional)": "Observaciones de muestra",
            "FECHA ULTIMA VENTA (Opcional)": "01/01/2021",
            "FECHA ULTIMA COMPRA (Opcional)": "01/01/2021",
            "PESO (KGS) (Opcional)": 10,
            "VOLUMEN (MT3) (Opcional)": 0,
            "COSTO MINIMO (Requerido)": 1500,
            "PORCENTAJE DE IVA (Requerido)": 16,
            "PORCENTAJE DE IEPS (Requerido)": 8,
            "CODIGO DE BARRAS (Opcional)": 7501207644812,
            "CLAVE DE LA MARCA (Opcional)": "GENERAL",
            "CLAVE DE LA FAMILIA (Opcional)": "HERRAMIENTAS",
            "CLAVE DE LA  SUBFAMILIA (Opcional)": "JARDINERIA",
            "CLAVE DE LA  SUBSUBFAMILIA (Opcional)": "METAL",
            "CLAVE DE LA SUBSUBSUBFAMILIA (Opcional)": "PALAS",
          },
        ];
      } else if (exportFileType === "warehouseArtciles") {
        return [
          {
            "CLAVE DEL ALMACEN (Requerido)": "General",
            "CLAVE DEL ARTICULO (Requerido)": "123ABC",
            "INVENTARIO (Requerido)": 10,
            "MINIMO (Requerido)": 0,
            "MAXIMO (Requerido)": 0,
          },
          {
            "CLAVE DEL ALMACEN (Requerido)": "General",
            "CLAVE DEL ARTICULO (Requerido)": "ART123",
            "INVENTARIO (Requerido)": 25,
            "MINIMO (Requerido)": 5,
            "MAXIMO (Requerido)": 10,
          },
        ];
      } else if (exportFileType === "providers") {
        return [
          {
            "NUMERO INTERNO DEL PROVEEDOR (Requerido)": "123",
            "CLAVE DEL PROVEEDOR (Requerido)": "123ABC",
            "NOMBRE (Requerido)": "Proveedor de muestra",
            "RFC DEL PROVEEDOR (Opcional)": "FLGV800823AD8",
            "CUENTA CONTABLE (Opcional)": "12345",
            "SUB CUENTA CONTABLE (Opcional)": "12345",
            "SUB SUB CUENTA CONTABLE (Opcional)": "12345",
            "URL (Opcional)": "https://www.coconutcontrol.com/",
            "OBSERVACIONES (Opcional)": "Observaciones de muestra",
            "DIRECCION (Opcional)": "Paseo de la rosa #12",
            "CODIGO POSTAL (Opcional)": "11111",
            "NUMERO INTERIOR (Opcional)": "12",
            "NUMERO EXTERIOR (Opcional)": "10",
            "NOMBRE DE CONTACTO (Opcional)": "Pedro",
            "APELLIDO PATERNO DE CONTACTO (Opcional)": "Rodriguez",
            "APELLIDO MATERNO DE CONTACTO (Opcional)": "Rodriguez",
            "TELEFONO DE CONTACTO (Opcional)": "3333333333",
            "CELULAR DE CONTACTO (Opcional)": "3333333333",
            "EMAIL DE CONTACTO (Opcional)": "pedro@correo.com",
            "DIAS DE CREDITO (Requerido)": 10,
            "LIMITE DE CREDITO (Requerido)": 10000,
            "PORCENTAJE DE DESCUENTO (Opcional)": 5,
          },
        ];
      } else if (exportFileType === "providersArticlesPrices") {
        return [
          {
            "CLAVE DEL PROVEEDOR (Requerido)": "123ABC",
            "CLAVE DEL ARTICULO (Requerido)": "123ABC",
            "CLAVE DEL ARTICULO DEL PROVEEDOR (Opcional)": "PROV123",
            "PRECIO DEL PROVEEDOR (Requerido)": 100,
            "COSTO MINIMO DEL ARTICULO (Opcional)": 90,
            "MULTIPLO DE UNIDADE PERMITIDAS EN COMPRA (Opcional)": 0,
            "ACTUALIZAR COSTO MINIMO DEL ARTICULO (Requerido)": "S",
          },
          {
            "CLAVE DEL PROVEEDOR (Requerido)": "1234ABCD",
            "CLAVE DEL ARTICULO (Requerido)": "123ABC",
            "CLAVE DEL ARTICULO DEL PROVEEDOR (Opcional)": "PROV123",
            "PRECIO DEL PROVEEDOR (Requerido)": 200,
            "COSTO MINIMO DEL ARTICULO (Opcional)": "",
            "MULTIPLO DE UNIDADE PERMITIDAS EN COMPRA (Opcional)": 5,
            "ACTUALIZAR COSTO MINIMO DEL ARTICULO (Requerido)": "N",
          },
        ];
      } else if (exportFileType === "priceLists") {
        return [
          {
            "CLAVE DE LISTA DE PRECIOS (Requerido)": "123ABC",
            "DESCRIPCION (Requerido)": "Decripciones de muestra",
            "PORCENTAJE UTILIDAD PREDETERMINADO (Requerido)": "40",
          },
        ];
      } else if (exportFileType === "bankMovements") {
        return [
          {
            "ID (Opcional)": "123FFFABC",
            "CLAVE CLIENTE (Opcional)": "ESCKEMPER123",
            "CLAVE PROVEEDOR (Opcional)": "",
            "FECHA (Requerido)": "01/01/2021",
            "IMPORTE (Requerido)": 1000,
            "REFERENCIA (Requerido)": " ABC123",
            "COMERCIO (Opcional)": "Comercio de muestra",
            "DESCRIPCION (Opcional)": "Descripcion de muestra",
            "AFECTA SALDO (Requerido)": "S",
            "MOSTRAR EN TESORERIA (Requerido)": "S",
          },
          {
            "ID (Opcional)": "456FFFABCD",
            "CLAVE CLIENTE (Opcional)": "",
            "CLAVE PROVEEDOR (Opcional)": "123ABC",
            "FECHA (Requerido)": "15/01/2021",
            "IMPORTE (Requerido)": -1500,
            "REFERENCIA (Requerido)": " ABCD123",
            "COMERCIO (Opcional)": "Comercio de muestra",
            "DESCRIPCION (Opcional)": "Descripcion de muestra",
            "AFECTA SALDO (Requerido)": "S",
            "MOSTRAR EN TESORERIA (Requerido)": "N",
          },
        ];
      } else if (exportFileType === "clients") {
        return [
          {
            "CLAVE DE CLIENTE (Requerido)": "ESCKEMPER123",
            "RAZON SOCIAL SIN TIPO DE SOCIEDAD (Requerido)":
              "ESCUELA KEMPER URGATE",
            "RFC (Requerido)": "EKU9003173C9",
            "CUENTA CONTABLE (Opcional)": "12345",
            "SUB CUENTA CONTABLE (Opcional)": "12345",
            "SUB SUB CUENTA CONTABLE (Opcional)": "12345",
            "CLAVE DE USO DEL CFDI (Requerido)": "G03",
            "REGIMEN FISCAL (Requerido)": "601",
            "LISTA DE PRECIOS PREDETERMINADA (Requerido)": "General",
            "DIAS DE CREDITO (Opcional)": "10",
            "LIMITE DE CREDITO (Opcional)": "10000",
            "OBSERVACIONES (Opcional)": "Cliente de prueba",
            "CODIGO POSTAL (Opcional)": "45000",
            "CLAVE DE ALMACEN (Opcional)": "General",
            "LISTA DE PRECIOS DEL CLIENTE (Opcional)":
              "General,Principal,Mayoreo",
            "CREDITO RESTANTE (Opcional)": "5000",
            "ENVIAR ESTADO DE CUENTA (Opcional)": "N",
          },
        ];
      } else if (exportFileType === "priceListsArticles") {
        return [
          {
            "CLAVE DEL ARTICULO (Requerido)": "ABC123",
            "CLAVE DE LA LISTA DE PRECIOS (Requerido)": "PROV123",
            "ES PRESENTACION (Opcional)": "S",
            "NOMBRE DE LA PRESENTACION (Opcional)": "Caja 2",
            "PORCENTAJE DE UTILIDAD (Requerido)": "30",
          },
          {
            "CLAVE DEL ARTICULO (Requerido)": "ART123",
            "CLAVE DE LA LISTA DE PRECIOS (Requerido)": "PROV234",
            "ES PRESENTACION (Opcional)": "N",
            "NOMBRE DE LA PRESENTACION (Opcional)": "",
            "PORCENTAJE DE UTILIDAD (Requerido)": "31.50",
          },
        ];
      } else if (exportFileType === "expenses") {
        return [
          {
            "CLAVE DEL PROVEEDOR O ACREEDOR (Requerido)": "123ABC",
            "FECHA DEL GASTO (Requerido)": "06/08/2022",
            "CLAVE DEL ALMACEN (Requerido)": "General",
            "NUMERO DE FACTURA (Requerido)": "123",
            "FECHA DE FACTURA (Requerido)": "06/08/2022",
            "COMENTARIOS (Opcional)": "Observaciones",
            "Tipo de periodo (FI = Fecha ingreso) (FF = Fecha factura) (Requerido)":
              "FI",
            "PLAZO EN DIAS (Opcional)": "10",
            "CONCEPTO (Requerido)": "Comisión",
            "DESCRIPCIÓN (Requerido)": "Comisión",
            "COSTO (El total más impuestos sera calculado automaticamente) (Requerdio)":
              "100",
            "PORCENTAJE DE IVA (Requerido)": "16",
            "PORCENTAJE DE IEPS (Requerido)": "0",
          },
          {
            "CLAVE DEL PROVEEDOR O ACREEDOR (Requerido)": "123ABC",
            "FECHA DEL GASTO (Requerido)": "06/08/2022",
            "CLAVE DEL ALMACEN (Requerido)": "General",
            "NUMERO DE FACTURA (Requerido)": "12345GHB",
            "FECHA DE FACTURA (Requerido)": "06/08/2022",
            "COMENTARIOS (Opcional)": "Observaciones",
            "Tipo de periodo (FI = Fecha ingreso) (FF = Fecha factura) (Requerido)":
              "FF",
            "PLAZO EN DIAS (Opcional)": "5",
            "CONCEPTO (Requerido)": "Comisión",
            "DESCRIPCIÓN (Requerido)": "Comisión",
            "COSTO (El total más impuestos sera calculado automaticamente) (Requerdio)":
              "50",
            "PORCENTAJE DE IVA (Requerido)": "16",
            "PORCENTAJE DE IEPS (Requerido)": "8",
          },
        ];
      } else if (exportFileType === "presentations") {
        return [
          {
            "CLAVE DEL ARTICULO (Requerido)": "100X22",
            "NOMBRE DE LA PRESENTACION (Requerido)": "Caja de 10 piezas 100X22",
            "MEDIDA DE LA PRESENTACION (Requerido)": "CAJA",
            "ES MAYOR QUE EL ARTICULO BASE (Requerido)": "S",
            "EQUIVALENCIA DE LA MEDIDA BASE DE ARTICULO (Requerido)": "1",
            "EQUIVALENCIA DE LA PRESENTACION (Requerido)": "10",
          },
          {
            "CLAVE DEL ARTICULO (Requerido)": "100X22",
            "NOMBRE DE LA PRESENTACION (Requerido)": "Caja de 2 piezas 100x22",
            "MEDIDA DE LA PRESENTACION (Requerido)": "CAJA",
            "ES MAYOR QUE EL ARTICULO BASE (Requerido)": "S",
            "EQUIVALENCIA DE LA MEDIDA BASE DE ARTICULO (Requerido)": "1",
            "EQUIVALENCIA DE LA PRESENTACION (Requerido)": "2",
          },
        ];
      } else if (exportFileType === "requestOrder") {
        return [
          {
            "CLAVE DEL ARTICULO (Requerido)": "100X22",
            "PRESENTACION (Requerido)": "Caja de 100 piezas 100X22",
            "CANTIDAD (Requerido)": "3",
            "PRECIO (Requerido)": "500",
          },
          {
            "CLAVE DEL ARTICULO (Requerido)": "100X22P",
            "PRESENTACION (Requerido)": "",
            "CANTIDAD (Requerido)": "1",
            "PRECIO (Requerido)": "",
          },
        ];
      } else if (exportFileType === "articlesPrediction") {
        return [
          {
            "CLAVE DEL ARTICULO (Requerido)": "100X22",
            "FECHA DE VENTA (Requerido)": "06/08/2022",
            "CANTIDAD (Requerido)": "250",
          },
          {
            "CLAVE DEL ARTICULO (Requerido)": "100X22",
            "FECHA DE VENTA (Requerido)": "22/08/2022",
            "CANTIDAD (Requerido)": "260",
          },
        ];
      }
    },
    deleteInformation() {
      this.file = null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Global/style/ImportModal.css";
</style>
