/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddClient()"
          >Agregar cliente o prospecto</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startImport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.importClients === 'S' &&
            permissions &&
            permissions.main.importClients === 'S'
          "
          >Importar clientes</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startExport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.exportClients === 'S' &&
            permissions &&
            permissions.main.exportClients === 'S'
          "
          >Exportar clientes</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de clientes: <b>{{ clients.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="clients"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedClients"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_CLI}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CLAVE_CLI"
      >
        <template #empty>
          <div class="has-text-centered">No hay clientes</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <p
              v-if="!checkIfValid(props.row[column.field])"
              @click="openPage(column.label)"
            >
              {{ "Sin información" }}
            </p>
            <p
              v-if="column.money && checkIfValid(props.row[column.field])"
              @click="openPage(column.label)"
            >
              {{ props.row[column.field] | money("MXN", 2) }}
            </p>
            <p
              v-if="!column.money && checkIfValid(props.row[column.field])"
              @click="openPage(column.label)"
            >
              {{ props.row[column.field] }}
            </p>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <AddClient :clientInformation="props.row" />
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ImportFileModal from "../../Global/components/ImportFileModal";
import AddClient from "./AddClient.vue";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ClientsList",
  components: { AddClient },
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedClients: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_CLI",
          label: "Clave del cliente",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortyByKey,
        },
        {
          field: "defaultPriceList",
          label: "Lista de precios",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_EMPRESA",
          label: "Razón social",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "RFC_CLI",
          label: "RFC",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_CLI",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "A_PATERNO",
          label: "Apellido paterno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "A_MATERNO",
          label: "Apellido materno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "LIMITE_CRED",
          label: "Limite de crédito",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "BALANCE_FIN",
          label: "Estado de cuenta actual",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "DIRECCION",
          label: "Direción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
      refreshLoading: false,
    };
  },
  async created() {},
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));

      if (this.$store.getters.SATCFDIUSES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
      }

      if (this.$store.getters.SATREGIMENES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
      }

      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));
      allPromises.push(this.$store.dispatch("GETCLIENTSUNLOCKS"));
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    openAddClient() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddClient,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "clients",
          nameImport: "Clientes",
          afterAction: "GETCLIENTS",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openPage(label) {
      if (label === "Estado de cuenta actual") {
        this.$router.push({ path: "/treasury/Balances" }).catch(() => {});
        this.$store.dispatch(
          "CHANGENEWHINT",
          "En esta pantalla podrás consultar los estados de cuenta de tus clientes y proveedores"
        );
      }
    },
    async startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de clientes",
        message: `¿Desea confirmar la exportación de los clientes a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: async () => {
          await this.reloadInformation();
          this.exportData();
        },
      });
    },
    exportData() {
      let payload = {
        data: this.clients.map((singleClient) => {
          const singleClientFormatted = {};

          singleClientFormatted["CLAVE DE CLIENTE (Requerido)"] =
            singleClient.CLAVE_CLI ? singleClient.CLAVE_CLI : "";
          singleClientFormatted["TELEFONO (Opcional)"] = singleClient.TELS_CLI
            ? singleClient.TELS_CLI
            : "";
          singleClientFormatted["CELULAR (Opcional)"] = singleClient.CEL_CLI
            ? singleClient.CEL_CLI
            : "";
          singleClientFormatted[
            "RAZON SOCIAL SIN TIPO DE SOCIEDAD (Requerido)"
          ] = singleClient.NOMBRE_EMPRESA ? singleClient.NOMBRE_EMPRESA : "";
          singleClientFormatted["RFC (Requerido)"] = singleClient.RFC_CLI
            ? singleClient.RFC_CLI
            : "";
          singleClientFormatted["CUENTA CONTABLE (Opcional)"] =
            singleClient.CUENTA_CONTABLE_CLIENTE
              ? singleClient.CUENTA_CONTABLE_CLIENTE
              : "";
          singleClientFormatted["CLAVE DE USO DEL CFDI (Requerido)"] = "";
          if (singleClient.CLAVE_USO_SAT) {
            for (const singleSatUses of this.cfdiUses) {
              if (singleClient.CLAVE_USO_SAT === singleSatUses._id) {
                singleClientFormatted["CLAVE DE USO DEL CFDI (Requerido)"] =
                  singleSatUses.CVE_USO_SAT;
              }
            }
          }
          singleClientFormatted["REGIMEN FISCAL (Requerido)"] = "";
          if (singleClient.CLAVE_REGIMEN) {
            for (const singleRegimen of this.regimenes) {
              if (singleClient.CLAVE_REGIMEN === singleRegimen._id) {
                singleClientFormatted["REGIMEN FISCAL (Requerido)"] =
                  singleRegimen.CVE_REG_SAT;
              }
            }
          }

          singleClientFormatted["GIRO (Opcional)"] = "";
          if (singleClient.GIRO_EMPRESA) {
            for (const singleRegimen of this.commercialBusiness) {
              if (singleClient.GIRO_EMPRESA === singleRegimen._id) {
                singleClientFormatted["GIRO (Opcional)"] =
                  singleRegimen.DESCRIPCION_GIR;
              }
            }
          }

          singleClientFormatted["LISTA DE PRECIOS PREDETERMINADA (Requerido)"] =
            "";
          if (singleClient.LISTA_PRECIOS_PRE) {
            singleClientFormatted[
              "LISTA DE PRECIOS PREDETERMINADA (Requerido)"
            ] = singleClient.LISTA_PRECIOS_PRE.CLAVE_LISTAP;
          }
          singleClientFormatted["DIAS DE CREDITO (Opcional)"] =
            singleClient.D_CREDITO ? singleClient.D_CREDITO : 0;
          singleClientFormatted["LIMITE DE CREDITO (Opcional)"] =
            singleClient.LIMITE_CRED ? singleClient.LIMITE_CRED : 0;
          singleClientFormatted["OBSERVACIONES (Opcional)"] =
            singleClient.OBSERVACIONES ? singleClient.OBSERVACIONES : "";
          singleClientFormatted["CODIGO POSTAL (Opcional)"] =
            singleClient.CP_CLI ? singleClient.CP_CLI : "";
          singleClientFormatted["CLAVE DE ALMACEN (Opcional)"] = "";
          if (singleClient.CLAVE_ALMACEN) {
            for (const singleWarehouse of this.warehouses) {
              if (singleWarehouse._id === singleClient.CLAVE_ALMACEN) {
                singleClientFormatted["CLAVE DE ALMACEN (Opcional)"] =
                  singleWarehouse.CLAVE_ALMACEN;
              }
            }
          }
          singleClientFormatted["LISTA DE PRECIOS DEL CLIENTE (Opcional)"] = "";
          if (singleClient.LISTAS_PRECIOS) {
            for (const singlePriceList of singleClient.LISTAS_PRECIOS) {
              singleClientFormatted[
                "LISTA DE PRECIOS DEL CLIENTE (Opcional)"
              ] += `,${singlePriceList.CLAVE_LISTAP}`;
            }
          }

          singleClientFormatted["ENVIAR ESTADO DE CUENTA (Opcional)"] = "N";
          if (singleClient.ENVIAR_ESTADO_CUENTA) {
            singleClientFormatted["ENVIAR ESTADO DE CUENTA (Opcional)"] = "S";
          }

          return singleClientFormatted;
        }),
        name: "clientes-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    sortyByKey(a, b, isAsc) {
      if (isAsc) {
        return a.CLAVE_CLI.localeCompare(b.CLAVE_CLI, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.CLAVE_CLI.localeCompare(a.CLAVE_CLI, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        if (clientWithProps.LISTA_PRECIOS_PRE) {
          clientWithProps.defaultPriceList =
            clientWithProps.LISTA_PRECIOS_PRE.CLAVE_LISTAP;
        }
        return clientWithProps;
      }).filter((singleClient) => {
        return singleClient.TIPO === "CL";
      });
    },
    regimenes() {
      return this.$store.getters.SATREGIMENES.map((singleRegimen) => {
        const regimenWithProps = { ...singleRegimen };
        if (regimenWithProps.TIPO_R === "A") {
          regimenWithProps.typeFormatted = "Ambos";
        } else if (regimenWithProps.TIPO_R === "F") {
          regimenWithProps.typeFormatted = "Persona física";
        } else if (regimenWithProps.TIPO_R === "M") {
          regimenWithProps.typeFormatted = "Persona moral";
        }
        return regimenWithProps;
      });
    },
    cfdiUses() {
      return this.$store.getters.SATCFDIUSES.map((singleUse) => {
        const useWithProps = { ...singleUse };
        if (useWithProps.TIPO_P === "A") {
          useWithProps.typeFormatted = "Ambos";
        } else if (useWithProps.TIPO_P === "F") {
          useWithProps.typeFormatted = "Persona física";
        } else if (useWithProps.TIPO_P === "M") {
          useWithProps.typeFormatted = "Persona moral";
        }
        return useWithProps;
      });
    },
    commercialBusiness() {
      return this.$store.getters.COMMERCIALBUSINESS.map(
        (singleCommercialBusiness) => {
          const commercialBusinessWithProps = { ...singleCommercialBusiness };
          if (commercialBusinessWithProps.TIPO === "C") {
            commercialBusinessWithProps.typeFormatted = "Clientes";
          } else if (commercialBusinessWithProps.TIPO === "P") {
            commercialBusinessWithProps.typeFormatted = "Proveedores";
          }
          return commercialBusinessWithProps;
        }
      ).filter((singleCommercialBusiness) => {
        return singleCommercialBusiness.TIPO === "C";
      });
    },
    warehouses() {
      return this.$store.getters.COMMERCIALBUSINESS.map(
        (singleCommercialBusiness) => {
          const commercialBusinessWithProps = { ...singleCommercialBusiness };
          if (commercialBusinessWithProps.TIPO === "C") {
            commercialBusinessWithProps.typeFormatted = "Clientes";
          } else if (commercialBusinessWithProps.TIPO === "P") {
            commercialBusinessWithProps.typeFormatted = "Proveedores";
          }
          return commercialBusinessWithProps;
        }
      ).filter((singleCommercialBusiness) => {
        return singleCommercialBusiness.TIPO === "C";
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
